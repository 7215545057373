import React, { ReactNode, createContext, useContext, useState } from "react";
import useAuthRequest from "../../utils/useAuthRequest";
import { parseISO, subMonths } from "date-fns";
import axios from "axios";

// Definitionen der Typen
type Dimension = {
	displayName: string;
	type: string;
	value: string;
};

type Product = {
	title: string;
	salesPrice: string;
	articleNumber: string;
	ean: string;
	fulfillmentStatus: string;
	mainImageBase64?: string;
};

type Order = {
	id: string;
	orderNumber: string;
	orderDate: string;
	products: Product[];
	fulfillmentStatus: string;
	internalFulfillmentStatus: string;
	handeledReturn: boolean;
	returnAnnounced: boolean;
};

// Initialer Zustand
const initialState = {
	ordersPick: [] as Order[],
	ordersPack: [] as Order[],
	ordersPickUp: [] as Order[],
	ordersHistory: [] as Order[],
	ordersReturn: [] as Order[],
	fetchOrders: async (status: string) => {},
	fetchOrdersHistory: async (page: number) => {},
	updateOrders: (status: string) => {},
};

type OrderProviderProps = {
	children: ReactNode;
};

const OrderContext = createContext(initialState);

export const OrderProvider: React.FC<OrderProviderProps> = ({ children }) => {
	const sendRequest = useAuthRequest();
	const [ordersPick, setOrdersPick] = useState<Order[]>([]);
	const [ordersPack, setOrdersPack] = useState<Order[]>([]);
	const [ordersPickUp, setOrdersPickUp] = useState<Order[]>([]);
	const [ordersHistory, setOrdersHistory] = useState<Order[]>([]);
	const [ordersReturn, setOrdersReturn] = useState<Order[]>([]);
	const [isDataLoaded, setIsDataLoaded] = useState<Record<string, boolean>>({
		pending: false,
		picked: false,
		packed: false,
		shipped: false,
	});
	const oneMonthsAgo = subMonths(new Date(), 1);

	const filterReturnOrders = (orders: Order[]) => {
		return orders.filter((order) => {
			const orderDate = parseISO(order.orderDate);
			return (
				orderDate > oneMonthsAgo &&
				order.fulfillmentStatus === "RETURNED" &&
				(order.handeledReturn === undefined || !order.handeledReturn)
			);
		});
	};

	const fetchOrders = async (status: string) => {
		if (isDataLoaded[status]) {
			return;
		}

		try {
			let params: Record<string, any> = {};
			const response = await sendRequest(`/api/otto/orders`, "get", {
				params,
			});

			if (response.data && Array.isArray(response.data)) {
				const filteredOrders = response.data.filter((order: Order) => {
					if (order.returnAnnounced && parseISO(order.orderDate) > oneMonthsAgo && !order.handeledReturn) {
						return status === "returned";
					} else if (["PROCESSABLE"].includes(order.fulfillmentStatus)) {
						return order.internalFulfillmentStatus === status;
					}
				});

				switch (status) {
					case "pending":
						setOrdersPick(filteredOrders);
						break;
					case "picked":
						setOrdersPack(filteredOrders);
						break;
					case "packed":
						setOrdersPickUp(filteredOrders);
						break;
					case "returned":
						setOrdersReturn(filteredOrders);
						break;
					default:
						console.error("Unbekannter Bestellstatus:", status);
						break;
				}
			} else {
				console.error("Unerwartetes Format der Antwort:", response.data);
			}
		} catch (error) {
			console.error(`Fehler beim Abrufen von Bestellungen (${status}):`, error);
		}

		setIsDataLoaded((prevState) => ({ ...prevState, [status]: true }));
	};

	const fetchOrdersHistory = async (page: number) => {
		try {
			const token = localStorage.getItem("authToken");
			const response = await axios.get(
				`/api/otto/orders?status=SENT,RETURNED,CANCELLED_BY_PARTNER,CANCELLED_BY_MARKETPLACE&page=${page}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.status === 200) {
				const data = response.data;
				setOrdersHistory((prevOrders) => {
					const newOrders = data.filter((order: Order) => {
						return !prevOrders.some((prevOrder) => prevOrder.id === order.id);
					});
					return [...prevOrders, ...newOrders];
				});
			}
		} catch (error) {
			console.error(`Fehler beim Abrufen von Bestellungen (page ${page}):`, error);
		}
	};

	const updateOrders = (status: string) => {
		fetchOrders(status);
	};

	return (
		<OrderContext.Provider
			value={{
				ordersPick,
				ordersPack,
				ordersPickUp,
				ordersHistory,
				ordersReturn,
				fetchOrders,
				updateOrders,
				fetchOrdersHistory,
			}}
		>
			{children}
		</OrderContext.Provider>
	);
};

export const useOrders = () => useContext(OrderContext);
