import { Card, CardFooter, CardHeader } from "src/components/ui/card";
import React, { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { getUserRoleFromToken } from "src/utils/auth";
import Sidebar from "../components/layout/sidebar/Sidebar";
import TopBar from "../components/layout/topbar/TopBar";
import { useOrders } from "../helper/OrderContext";
import { useOrdersShop } from "../helper/OrderWebshopContext";
import "./AdminLayout.css";
import { ArrowTopRightIcon } from "@radix-ui/react-icons";
import Dashboard from "../containers/dashboard/Dashboard";

const AdminLayout: React.FC = () => {
	const [isSidebarExpanded, setSidebarExpanded] = useState(true);
	const { fetchOrders } = useOrders();
	const { fetchOrdersShop } = useOrdersShop();
	const userRole = getUserRoleFromToken();

	const fetchData = useCallback(() => {
		if (userRole === "bucher") {
			fetchOrders("pending");
			fetchOrders("picked");
			fetchOrders("packed");
			fetchOrders("shipped");
			fetchOrders("returned");
		}
		fetchOrdersShop("pending");
		fetchOrdersShop("picked");
		fetchOrdersShop("packed");
		fetchOrdersShop("sent");
		fetchOrdersShop("returned");
	}, [fetchOrders, fetchOrdersShop, userRole]);

	useEffect(() => {
		fetchData();
		const interval = setInterval(fetchData, 360000);
		return () => clearInterval(interval);
	}, [fetchData]);
	const router = useLocation();
	const isHomePage = router.pathname === "/";
	return (
		<div
			style={{ display: "flex", height: "100vh", overflow: "hidden" }}
			className="bg-white dark:bg-sidebarGray-800"
		>
			<Sidebar isSidebarExpanded={isSidebarExpanded} setSidebarExpanded={setSidebarExpanded} />
			<div style={{ flex: 1, overflowY: "scroll" }} className="bg-sidebarGrayLight-500 dark:bg-sidebarGray-700">
				<main className="default-layout__content relative">
					<TopBar isSidebarExpanded={isSidebarExpanded} />
					{isHomePage && userRole === "bucher" ? (
						<>
							<Dashboard />
						</>
					) : (
						<p></p>
					)}
					<Outlet />
				</main>
			</div>
		</div>
	);
};

export default AdminLayout;
