// src/context/ContentProvider.tsx

import React, { createContext, useState, PropsWithChildren } from "react";
import axios from "axios";
import ContentCategory from "../types/ContentCategory";

interface ContentContextType {
	categories: ContentCategory[];
	fetchCategories: () => void;
	addCategory: (name: string) => Promise<void>;
	deleteCategory: (id: number) => Promise<void>;
	uploadImages: (categoryId: number, images: File[], title?: string) => Promise<void>;
	deleteImage: (id: number) => Promise<void>;
}

export const ContentContext = createContext<ContentContextType>({} as ContentContextType);

export const ContentProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
	const [categories, setCategories] = useState<ContentCategory[]>([]);

	async function fetchCategories() {
		try {
			const response = await axios.get("/api/content/categories", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("authToken")}`,
				},
			});

			if (response.status === 200) {
				setCategories(response.data);
			} else {
				console.error("Fehler beim Abrufen der Kategorien:", response.status);
			}
		} catch (error) {
			console.error("Fehler beim Abrufen der Kategorien:", error);
		}
	}

	async function addCategory(name: string) {
		try {
			const response = await axios.post(
				"/api/content/add-category",
				{ name },
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("authToken")}`,
					},
				}
			);

			if (response.status === 201) {
				fetchCategories();
			} else {
				console.error("Fehler beim Hinzufügen der Kategorie:", response.status);
			}
		} catch (error) {
			console.error("Fehler beim Hinzufügen der Kategorie:", error);
		}
	}

	async function deleteCategory(id: number) {
		try {
			const response = await axios.delete(`/api/content/delete-category/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("authToken")}`,
				},
			});

			if (response.status === 200) {
				fetchCategories();
			} else {
				console.error("Fehler beim Löschen der Kategorie:", response.status);
			}
		} catch (error) {
			console.error("Fehler beim Löschen der Kategorie:", error);
		}
	}

	async function uploadImages(categoryId: number, images: File[], title?: string) {
		try {
			const token = localStorage.getItem("authToken");
			const formData = new FormData();
			formData.append("categoryId", categoryId.toString());
			if (title) formData.append("title", title);

			images.forEach((image) => {
				formData.append("imageFiles", image);
			});

			const response = await axios.post("/api/content/upload-images", formData, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response.status === 200) {
				fetchCategories();
			} else {
				console.error("Fehler beim Hochladen der Bilder:", response.status);
			}
		} catch (error) {
			console.error("Fehler beim Hochladen der Bilder:", error);
		}
	}

	async function deleteImage(id: number) {
		try {
			const response = await axios.delete(`/api/content/delete-image/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("authToken")}`,
				},
			});

			if (response.status === 200) {
				fetchCategories();
			} else {
				console.error("Fehler beim Löschen des Bildes:", response.status);
			}
		} catch (error) {
			console.error("Fehler beim Löschen des Bildes:", error);
		}
	}

	return (
		<ContentContext.Provider
			value={{
				categories,
				fetchCategories,
				addCategory,
				deleteCategory,
				uploadImages,
				deleteImage,
			}}
		>
			{children}
		</ContentContext.Provider>
	);
};
