import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronUpIcon,
	ChevronDownIcon,
	Cross2Icon,
	DotsHorizontalIcon,
	ImageIcon,
	MixerHorizontalIcon,
	Pencil1Icon,
	StarIcon,
	CaretSortIcon,
	PlusCircledIcon,
	TrashIcon,
	CheckCircledIcon,
	CheckIcon,
	UploadIcon,
	DownloadIcon,
	GlobeIcon,
	ExclamationTriangleIcon,
	EyeOpenIcon,
	Pencil2Icon,
} from "@radix-ui/react-icons";
import { Badge } from "src/components/ui/badge";
import { Button } from "src/components/ui/button";
import { Card } from "src/components/ui/card";
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetDescription,
	SheetFooter,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "src/components/ui/sheet";
import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from "src/components/ui/drawer";
import { CustomImageFile, CustomProduct } from "../../helper/CustomProductContext";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../components/ui/table";
import { Skeleton } from "src/components/ui/skeleton";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "src/components/ui/select";
import { useCustomProducts } from "../../helper/CustomProductContext";
import { Textarea } from "src/components/ui/textarea";
import { Checkbox } from "@/src/components/ui/checkbox";
import UpdateCustomProductSheet from "../sheet/UpdateCustomProductSheet";

const CustomProductTable: React.FC = () => {
	const scrollToTop = useCallback(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);
	const {
		customProductList = [],
		fetchCustomProducts,
		searchProducts,
		uploadImages,
		fetchProductGroups,
		productGroups,
		totalPages,
		currentPage,
		setCurrentPage,
		categories,
		createProduct,
		deleteImage,
		deleteProduct,
		totalProducts,
		fetchProductById,
		updateProduct,
	} = useCustomProducts();

	const [articleId, setArticleId] = useState<number>(0);
	const [description, setDescription] = useState<string>("");
	const [unit, setUnit] = useState<string>("");
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [userSearchQuery, setUserSearchQuery] = useState<string>("");
	const [additionalInfo, setAdditionalInfo] = useState<string>("");
	const [price, setPrice] = useState<number>(0);
	const [barcode, setBarcode] = useState<string>("");
	const [weight, setWeight] = useState<number>(0);
	const [weightUnit, setWeightUnit] = useState<string>("");
	const [basePriceUnit, setBasePriceUnit] = useState<string>("");
	const [available, setAvailable] = useState<number>(0);
	const [productGroup, setProductGroup] = useState<string>("");
	const [imageFiles, setImageFiles] = useState<CustomImageFile[]>([]);
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const [isDragging, setIsDragging] = useState<boolean>(false);
	const [selectedGroup, setSelectedGroup] = useState<string | undefined>(undefined);
	const [sortBy, setSortBy] = useState<string>("articleId");
	const [loading, setLoading] = useState<boolean>(false);
	const [active, setActive] = useState<boolean>(false);
	const [showPrice, setShowPrice] = useState<boolean>(false);
	const [sortDirection, setSortDirection] = useState<string>("ASC");
	const [selectedSort, setSelectedSort] = useState<string | undefined>(undefined);
	const [openDrawerId, setOpenDrawerId] = useState<number | null>(null);
	const memoizedFetchCustomProducts = useCallback(() => {
		fetchCustomProducts(currentPage, sortBy, sortDirection, selectedGroup);
	}, [currentPage, sortBy, sortDirection, selectedGroup]);
	const memoizedSearchProducts = useCallback(
		(userSearchQuery: string) => {
			searchProducts(userSearchQuery, 1);
		},
		[searchProducts]
	);

	useEffect(() => {
		if (userSearchQuery) {
			const delayDebounceFn = setTimeout(() => {
				memoizedSearchProducts(userSearchQuery);
			}, 500);

			return () => clearTimeout(delayDebounceFn);
		} else {
			memoizedFetchCustomProducts();
			fetchProductGroups();
		}
	}, [userSearchQuery, selectedGroup, selectedSort, currentPage, customProductList]);

	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(event.target.files || []);
		setSelectedImages([...selectedImages, ...files]);
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const files = Array.from(event.dataTransfer.files);
		setSelectedImages([...selectedImages, ...files]);
		setIsDragging(false);
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	const handleDragEnter = () => {
		setIsDragging(true);
	};

	const handleDragLeave = () => {
		setIsDragging(false);
	};

	const removeImage = (indexToRemove: number) => {
		setSelectedImages((prevImages) => prevImages.filter((_, index) => index !== indexToRemove));
	};

	const handleGroupChange = useCallback(
		(value: string) => {
			if (value !== selectedGroup) {
				setCurrentPage(1);
				setSelectedGroup(value);
			}
		},
		[selectedGroup, setCurrentPage]
	);

	const handleCreateProduct = async () => {
		try {
			createProduct(
				articleId,
				description,
				unit,
				searchTerm,
				"",
				price,
				barcode,
				weight,
				weightUnit,
				basePriceUnit,
				available,
				productGroup,
				showPrice,
				active,
				selectedImages
			);
		} catch (error) {
			console.error("Error while creating product", error);
		}
	};

	const handleSortChange = useCallback(
		(field: string) => {
			const isAsc = sortBy === field && sortDirection === "ASC";
			setSortBy(field);
			setSortDirection(isAsc ? "DESC" : "ASC");
			setSelectedSort(`${field}-${isAsc ? "DESC" : "ASC"}`);
		},
		[sortBy, sortDirection]
	);

	const handlePageSelectInc = useCallback(() => {
		const newPage = currentPage < totalPages ? currentPage + 1 : currentPage;
		if (newPage !== currentPage) {
			setCurrentPage(newPage);
			scrollToTop();
		}
	}, [currentPage, totalPages, setCurrentPage, scrollToTop]);

	const handlePageSelectDec = useCallback(() => {
		const newPage = currentPage > 1 ? currentPage - 1 : 1;
		if (newPage !== currentPage) {
			setCurrentPage(newPage);
			scrollToTop();
		}
	}, [currentPage, setCurrentPage, scrollToTop]);

	const handlePageSelectFirst = useCallback(() => {
		if (currentPage !== 1) {
			setCurrentPage(1);
			scrollToTop();
		}
	}, [currentPage, setCurrentPage, scrollToTop]);

	const handlePageSelectLast = useCallback(() => {
		if (currentPage !== totalPages) {
			setCurrentPage(totalPages);
			scrollToTop();
		}
	}, [totalPages, currentPage, setCurrentPage, scrollToTop]);

	const handleSearch = useCallback(() => {
		memoizedSearchProducts(userSearchQuery);
	}, [userSearchQuery, memoizedSearchProducts]);

	const handleResetFilters = useCallback(() => {
		setSelectedGroup(undefined);
		setSelectedSort(undefined);
		setSortDirection("ASC");
		setSortBy("articleId");
		setUserSearchQuery("");
		memoizedFetchCustomProducts();
	}, [memoizedFetchCustomProducts]);

	const renderPageNumbers = useMemo(() => {
		return <div className="flex items-center justify-center">{`Seite ${currentPage} von ${totalPages}`}</div>;
	}, [currentPage, totalPages]);

	const ProductImageCell: React.FC<{
		imageUrl: string;
		articleId: number;
	}> = ({ imageUrl, articleId }) => {
		const [isLoading, setIsLoading] = useState(true);

		return (
			<TableCell className="font-medium">
				{imageUrl ? (
					<div className="relative">
						{isLoading && <Skeleton className="h-16 w-16 absolute top-0 left-0 rounded-lg" />}
						<img
							src={`${imageUrl}`}
							alt={articleId.toString()}
							width={48}
							height={48}
							className={`w-16 h-16 object-cover ${isLoading ? "opacity-0" : "opacity-100"}`}
							onLoad={() => setIsLoading(false)}
						/>
					</div>
				) : (
					<div className="border flex items-center justify-center h-16 w-16">
						<ImageIcon className="w-6 h-6 text-sidebarGray-500" />
					</div>
				)}
			</TableCell>
		);
	};

	const getSortIcon = (field: string) => {
		if (sortBy === field) {
			return sortDirection === "ASC" ? <ChevronUpIcon className="ml-2" /> : <ChevronDownIcon className="ml-2" />;
		} else {
			return <CaretSortIcon className="ml-2" />;
		}
	};

	return (
		<>
			<div className="pb-8 pt-8">
				<h1 className="font-medium text-2xl">Administrations- und Content-Verwaltungstool</h1>
				<div>
					<Sheet key={"bottom"}>
						<SheetTrigger asChild className="w-max my-8">
							<Button
								variant={"ghost"}
								className="flex items-center justify-center gap-2 border-dotted border-[1px] border-sidebarGray-500 rounded-lg"
							>
								<PlusCircledIcon className="w-5 h-5" />
								Produkt hinzufügen
							</Button>
						</SheetTrigger>
						<SheetContent className="h-[95vh] px-[20%] m-0 overflow-y-scroll" side={"bottom"}>
							<SheetHeader className="flex flex-col p-0 m-0">
								<SheetTitle className="text-3xl py-8 flex flex-col items-start justify-start p-0 m-0">
									Neues Produkt hinzufügen
								</SheetTitle>
								<SheetDescription></SheetDescription>
							</SheetHeader>
							<section className="flex items-start justify-start gap-4 p-0 m-0">
								<div className="grid grid-cols-1 w-3/5 gap-4">
									<Card className="w-full h-full gap-4 py-8 pl-16 pr-16 items-start justify-start flex-grow">
										<h3 className="mb-2 text-lg font-medium">Artikelinformationen</h3>
										<div className="flex items-center justify-center gap-4">
											<span className="w-full">
												<label htmlFor="articleId">Art.-Nr.</label>
												<Input
													id="articleId"
													type="text"
													value={articleId}
													onChange={(e) => setArticleId(Number(e.target.value))}
												></Input>
											</span>
											<span className="w-full">
												<label htmlFor="barcode">SKU</label>
												<Input
													id="barcode"
													type="text"
													value={barcode}
													onChange={(e) => setBarcode(e.target.value)}
												></Input>
											</span>
											<span className="w-full">
												<label htmlFor="quantity">Bestand</label>
												<Input
													id="quantity"
													type="number"
													value={available}
													onChange={(e) => setAvailable(Number(e.target.value))}
												></Input>
											</span>
										</div>
									</Card>
									<Card className="flex flex-col w-full h-full gap-4 py-8 pl-16 pr-16 items-start justify-start">
										<h3 className="mb-2 text-lg font-medium">Allgemeine Informationen</h3>
										<div className="w-full flex items-center justify-center gap-4">
											<span className="w-full">
												<label htmlFor="searchTerm">Produktname</label>
												<Input
													id="searchTerm"
													type="text"
													value={searchTerm}
													onChange={(e) => setSearchTerm(e.target.value)}
												></Input>
											</span>
										</div>
										<div className="w-full flex items-center justify-center gap-4">
											<span className="w-full">
												<label htmlFor="description">Beschreibung</label>
												<Textarea
													placeholder="Beschreiben Sie ihr Produkt..."
													id="description"
													typeof="text"
													onChange={(e) => setDescription(e.target.value)}
												/>
											</span>
										</div>
									</Card>
									<Card className="w-full h-full gap-4 py-8 pl-16 pr-16 items-start justify-start">
										<h3 className="mb-2 text-lg font-medium">Bilder hochladen</h3>
										<div
											className={`border-2 border-dashed border-gray-400 p-4 rounded-lg w-full flex justify-center items-center ${
												isDragging ? "bg-sidebarGray-500" : "bg-transparent"
											}`}
											onDrop={handleDrop}
											onDragOver={handleDragOver}
											onDragEnter={handleDragEnter}
											onDragLeave={handleDragLeave}
										>
											{isDragging ? (
												<p className="text-sidebarGrayLight-800 flex items-center justify-center gap-4">
													<DownloadIcon className="w-16 h-16" />
													Lassen Sie die Dateien los, um sie hochzuladen
												</p>
											) : (
												<label className="cursor-pointer text-gray-500">
													<span className="flex items-center justify-center gap-4">
														<ImageIcon className="w-16 h-16" />
														Ziehen Sie Ihre Bilder hierher oder klicken Sie
													</span>
													<input
														type="file"
														accept="image/*"
														multiple
														onChange={handleImageUpload}
														className="hidden"
													/>
												</label>
											)}
										</div>
										{selectedImages.length > 0 && (
											<div className="mt-4 grid grid-cols-2 gap-4">
												{selectedImages.map((image, index) => {
													const imageUrl = URL.createObjectURL(image);
													return (
														<div key={index} className="relative">
															<img
																src={imageUrl}
																alt={`preview-${index}`}
																className="w-full h-auto object-cover rounded-md"
															/>
															<TrashIcon
																className="p-2 w-8 h-8 text-sidebarGrayLight-400 rounded-md bg-sidebarGray-700 absolute right-1 top-1 hover:pointer"
																onClick={() => removeImage(index)}
															/>
														</div>
													);
												})}
											</div>
										)}
									</Card>
								</div>
								<div className="grid grid-cols-1 w-2/5 gap-4">
									<Card className="flex h-full flex-grow flex-col w-full gap-4 py-8 pl-16 pr-16 items-start justify-start">
										<h3 className="mb-2 text-lg font-medium">Kategorie</h3>
										<label htmlFor="select">Produktkategorie</label>
										<Select>
											<SelectTrigger className="w-full" id="select">
												<SelectValue placeholder="Wählen Sie eine Kategorie aus" />
											</SelectTrigger>
											<SelectContent>
												<SelectGroup className="p-0 m-0 flex flex-col items-start justify-start">
													<SelectLabel className="p-0 m-0">Produktkategorie</SelectLabel>
													{Array.isArray(productGroups) && productGroups.length > 0 ? (
														productGroups.map((group) => (
															<SelectItem className="p-0 m-0" value={group} key={group}>
																{group}
															</SelectItem>
														))
													) : (
														<></>
													)}
												</SelectGroup>
											</SelectContent>
										</Select>
										<Label htmlFor="productgroup"></Label>
										<Input
											id="productgroup"
											type="text"
											value={productGroup}
											onChange={(e) => setProductGroup(e.target.value)}
										></Input>
									</Card>
									<Card className="flex flex-col w-full gap-4 py-8 pl-16 pr-16 items-start justify-start">
										<h3 className="mb-2 text-lg font-medium">Preisinformationen</h3>
										<div className="w-full flex items-center justify-center gap-4">
											<span className="w-full">
												<label htmlFor="basePrice">Basispreis</label>
												<div className="relative w-full">
													<div className="border bg-sidebarGray-500 border-sidebarGray-500 rounded-sm absolute top-1 left-1 px-2 py-[1px]">
														EUR
													</div>
													<Input
														id="basePrice"
														type="number"
														value={price}
														onChange={(e) => setPrice(Number(e.target.value))}
														className="pl-16"
													></Input>
												</div>
											</span>
										</div>
									</Card>
									<Card className="flex flex-col w-full gap-4 py-8 pl-16 pr-16 items-start justify-evenly">
										<h3 className="mb-2 text-lg font-medium">Produktstatus</h3>
										<div className="flex flex-col items-start justify-start">
											<Label htmlFor="active">Aktiv</Label>
											<input
												checked={active}
												type="checkbox"
												id="active"
												onChange={(e) => setActive(e.target.checked)}
											></input>
										</div>
										<div className="flex flex-col items-start justify-start">
											<Label htmlFor="active">Preis anzeigen</Label>
											<input
												checked={showPrice}
												type="checkbox"
												id="active"
												onChange={(e) => setShowPrice(e.target.checked)}
											></input>
										</div>
									</Card>
								</div>
							</section>
							<SheetFooter className="mt-12 flex flex-row items-end justify-end">
								<SheetClose className="flex items-center justify-center gap-2">
									<Button
										variant={"default"}
										className="flex items-center justify-center gap-4"
										onClick={handleCreateProduct}
									>
										Produkt hochladen
										<UploadIcon className="w-4 h-4" />
									</Button>
									<Button variant="outline">Schließen</Button>
								</SheetClose>
							</SheetFooter>
						</SheetContent>
					</Sheet>
				</div>
			</div>
			<div className="flex items-center justify-center gap-4 px-2 py-6 lg:px-0">
				<div className="w-full flex items-center justify-center gap-4 px-2 lg:px-0">
					<Sheet>
						<SheetTrigger asChild>
							<Button variant="default" className="flex items-center justify-center gap-2">
								Filter
								<MixerHorizontalIcon />
							</Button>
						</SheetTrigger>
						<SheetContent side={"left"} className="z-[70]">
							<SheetHeader>
								<SheetTitle className="text-2xl">Filter</SheetTitle>
							</SheetHeader>
							<div>
								{/* <Select
									value={selectedSort}
									onValueChange={handleSortChange}
								>
									<SelectTrigger className="w-full mt-6">
										<SelectValue placeholder="Sortierung" />
									</SelectTrigger>
									<SelectContent className="z-[71]">
										<SelectGroup>
											<SelectLabel>
												Sortierung
											</SelectLabel>
											<SelectItem value="price-asc">
												Preis aufsteigend
											</SelectItem>
											<SelectItem value="price-desc">
												Preis absteigend
											</SelectItem>
											<SelectItem value="searchTerm-asc">
												A - Z
											</SelectItem>
											<SelectItem value="searchTerm-desc">
												Z - A
											</SelectItem>
											<SelectItem value="available-asc">
												Bestand aufsteigend
											</SelectItem>
											<SelectItem value="available-desc">
												Bestand absteigend
											</SelectItem>
										</SelectGroup>
									</SelectContent>
								</Select> */}
								<Select value={selectedGroup} onValueChange={handleGroupChange}>
									<SelectTrigger className="w-full mt-6">
										<SelectValue placeholder="Warengruppe" />
									</SelectTrigger>
									<SelectContent className="z-[90]">
										<SelectGroup>
											<SelectLabel>Warengruppe</SelectLabel>
											{Array.isArray(productGroups) && productGroups.length > 0 ? (
												productGroups.map((group) => (
													<SelectItem value={group} key={group}>
														{group}
													</SelectItem>
												))
											) : (
												<></>
											)}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
							<SheetFooter className="flex-col items-center justify-center mt-8">
								<SheetClose asChild>
									<Button type="submit" className="w-full">
										Schließen
									</Button>
								</SheetClose>
							</SheetFooter>
							<Button variant="ghost" className="w-full mt-4" onClick={handleResetFilters}>
								Filter zurücksetzen
							</Button>
						</SheetContent>
					</Sheet>
					<Input
						type="text"
						placeholder="Suche..."
						value={userSearchQuery}
						onChange={(e) => setUserSearchQuery(e.target.value)}
					/>
					<Button className="w-max" variant={"default"} onClick={handleSearch}>
						Suche
					</Button>
				</div>
			</div>
			<div className=" flex items-center justify-start gap-2">
				<Badge className="bg-transparent h-8 w-0 p-0 m-0 shadow-none"></Badge>
				{selectedSort && (
					<Badge className="flex items-center justify-between gap-1 w-max" variant={"default"}>
						{sortBy === "price" && <p>Preis</p>}
						{sortBy === "searchTerm" && <p>Name</p>}
						{sortDirection === "DESC" && <p>absteigend</p>}
						{sortDirection === "ASC" && <p>aufsteigend</p>}
						<button
							onClick={() => (setSelectedSort(undefined), setSortBy(""), setSortDirection(""))}
							className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
						>
							<Cross2Icon />
						</button>
					</Badge>
				)}
				{selectedGroup && (
					<Badge className="flex items-center justify-between gap-2 w-max">
						{selectedGroup}
						<button
							onClick={() => setSelectedGroup(undefined)}
							className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
						>
							<Cross2Icon />
						</button>
					</Badge>
				)}
				{userSearchQuery && (
					<Badge className="flex items-center justify-between gap-2 w-max">
						{userSearchQuery}
						<button
							onClick={() => setUserSearchQuery("")}
							className="p-1 m-0 rounded-full hover:bg-sidebarGray-500"
						>
							<Cross2Icon />
						</button>
					</Badge>
				)}
			</div>
			{Array.isArray(customProductList) && customProductList.length > 0 ? (
				<div className="flex h-12 w-full items-center justify-end gap-4 -space-x-px text-sm">
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectFirst}
					>
						Start
					</button>
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectDec}
					>
						<ChevronLeftIcon />
						Zurück
					</button>
					{renderPageNumbers}
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectInc}
					>
						Weiter
						<ChevronRightIcon />
					</button>
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectLast}
					>
						Ende
					</button>
				</div>
			) : (
				<></>
			)}
			<Table className="px-8 z-40">
				<TableHeader className="sticky">
					<TableRow>
						<TableHead className="w-[100px]"></TableHead>
						<TableHead onClick={() => handleSortChange("articleId")} className="cursor-pointer w-[100px]">
							<div className="flex items-center whitespace-nowrap">
								Art.-Nr.
								{getSortIcon("articleId")}
							</div>
						</TableHead>
						<TableHead
							onClick={() => handleSortChange("searchTerm")}
							className="cursor-pointer flex items-center"
						>
							<div className="flex items-center">
								Name
								{getSortIcon("searchTerm")}
							</div>
						</TableHead>
						<TableHead>Kategorie</TableHead>
						<TableHead onClick={() => handleSortChange("price")} className="cursor-pointer w-[100px] px-0">
							<div className="flex items-center text-right justify-center">
								Preis
								{getSortIcon("price")}
							</div>
						</TableHead>
						<TableHead
							onClick={() => handleSortChange("available")}
							className="cursor-pointer w-[100px] text-right px-0"
						>
							<div className="flex items-center justify-center">
								Bestand
								{getSortIcon("available")}
							</div>
						</TableHead>
						<TableHead className="text-right w-[150px]"></TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{customProductList && customProductList.length > 0 ? (
						customProductList.map((product) => (
							<TableRow key={product.articleId} className="dark:text-white">
								<ProductImageCell
									imageUrl={product.images?.[0]?.imageUrl || ""}
									articleId={product.articleId}
								/>
								<TableCell className="font-medium text-center">{product.articleId}</TableCell>
								<TableCell className="font-medium">{product.searchTerm}</TableCell>
								<TableCell>{product.productGroup}</TableCell>
								<TableCell className="font-medium text-center">{product.price}€</TableCell>
								<TableCell className="text-center">{product.available}</TableCell>
								<TableCell className="h-full flex items-center justify-center gap-2">
									<Drawer>
										<DrawerTrigger asChild className="w-12 h-12">
											<Button variant="outline">
												<EyeOpenIcon className="w-5 h-5" />
											</Button>
										</DrawerTrigger>
										<DrawerContent className="h-[95vh] px-12">
											<DrawerHeader className="flex flex-col">
												<DrawerTitle className="text-3xl flex flex-col items-start justify-start p-0 m-0">
													{product.searchTerm}
												</DrawerTitle>
												<DrawerDescription>{product.articleId}</DrawerDescription>
											</DrawerHeader>
											<section className="flex items-start justify-start gap-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-sidebarGray-700 scrollbar-track-sidebarGray-800 custom-scrollbar overflow-x-hidden">
												<div className="w-2/3 flex flex-col items-start justify-start gap-4">
													<Card className="w-full flex flex-col py-8 pl-16 pr-16 items-start justify-start">
														<span className="text-base text-gray-500 pl-1 pt-1">
															Produktbilder
														</span>
														<div className="grid grid-cols-3 gap-2 mt-4">
															{Array.isArray(product.images) &&
															product.images.length === 0 ? (
																<ImageIcon className="w-96 h-96 text-sidebarGray-500" />
															) : (
																product.images.map((image: CustomImageFile) => (
																	<div
																		key={image.id}
																		className="relative border-sidebarGray-500 border-[1px]"
																	>
																		<img
																			src={`${image.imageUrl}`}
																			alt={product.searchTerm}
																			className="w-full h-full max-h-[250px] object-contain"
																		/>
																	</div>
																))
															)}
														</div>
													</Card>
												</div>
												<div className="flex flex-col w-1/3 gap-4">
													<Card className="w-full gap-4 grid grid-cols-1 py-8 pl-16 pr-16 items-start justify-start">
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">ID</span>
															<p className="text-lg">{product.articleId}</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">Produktname</span>
															{product.searchTerm ? (
																<p className="text-lg">{product.searchTerm}</p>
															) : (
																<p>-</p>
															)}
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Produktkategorie
															</span>
															<p className="text-lg">{product.productGroup}</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">Beschreibung</span>
															{product.description ? (
																<p className="text-lg">{product.description}</p>
															) : (
																<p>-</p>
															)}
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">Details</span>
															<p className="text-lg">{product.additionalInfo}</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">Bestand</span>
															<p className="text-lg">{product.available}</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">Gewicht</span>
															<p className="text-lg">
																{product.weight}
																&nbsp;
																{product.weightUnit}
															</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">Aktiv</span>
															<p className="text-lg">
																{product.active.toString() === "true" ? (
																	<span>Artikel aktiv</span>
																) : (
																	<span>Artikel inaktiv</span>
																)}
															</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">
																Preis anzeigen
															</span>
															<p className="text-lg">
																{product.showPrice.toString() === "true" ? (
																	<span>Preis aktiv</span>
																) : (
																	<span>Preis inaktiv</span>
																)}
															</p>
														</div>
														<div className="flex flex-col item-start justify-start">
															<span className="text-sm text-gray-500">Preis</span>
															<p className="text-lg">
																{product.price}
																&nbsp;
																{product.basePriceUnit}
															</p>
														</div>
													</Card>
													<Card className="w-full gap-4 grid grid-cols-1 py-8 pl-16 pr-16 items-start justify-start border-red-400 border-[1px]">
														<span className="flex items-center gap-4  justify-start text-base text-gray-500 pt-1">
															<ExclamationTriangleIcon className="w-6 h-6 text-red-400" />
															Produkt endgültig löschen
														</span>
														<small>
															Bitte beachten Sie, dass dieser Vorgang nicht rückgängig
															gemacht werden kann. Auch nicht von uns wenn es sich um ein
															Versehen handelt.
														</small>
														<div>
															<Button
																variant={"destructive"}
																className="text-red-400 border-[1px] border-red-400"
																onClick={() => deleteProduct(product.articleId)}
															>
																Produkt jetzt löschen
															</Button>
														</div>
													</Card>
												</div>
											</section>
											<DrawerFooter className="flex flex-row items-end justify-end">
												<DrawerClose>
													<Button variant="outline">Schließen</Button>
												</DrawerClose>
											</DrawerFooter>
										</DrawerContent>
									</Drawer>
									<Sheet
										key={"bottom"}
										open={openDrawerId === product.articleId}
										onOpenChange={(open) => setOpenDrawerId(open ? product.articleId : null)}
									>
										<SheetTrigger asChild className="w-12 h-12">
											<Button variant={"outline"}>
												<Pencil2Icon className="w-5 h-5" />
											</Button>
										</SheetTrigger>
										<SheetContent
											className="h-[95vh] px-[20%] m-0 overflow-y-scroll"
											side={"bottom"}
										>
											<SheetHeader className="flex flex-col p-0 m-0">
												<SheetTitle className="text-3xl py-8 flex flex-col items-start justify-start p-0 m-0">
													Produkt bearbeiten
												</SheetTitle>
											</SheetHeader>
											<UpdateCustomProductSheet
												passthroughArcticleId={product.articleId}
												onClose={() => setOpenDrawerId(null)}
											/>
											<SheetFooter className="flex-col items-center justify-center">
												<SheetClose asChild>
													<Button type="submit" variant={"outline"} className="w-full">
														Schließen
													</Button>
												</SheetClose>
											</SheetFooter>
										</SheetContent>
									</Sheet>
									{/* <Popover>
										<PopoverTrigger asChild className="w-12 h-12">
											<Button variant="outline">
												<StarIcon className="w-5 h-5" />
											</Button>
										</PopoverTrigger>
										<PopoverContent className="w-56">
											<div className="flex items-center justify-start gap-2">
												<StarIcon />
												<h1>Schnellzugriff</h1>
											</div>
										</PopoverContent>
									</Popover> */}
								</TableCell>
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell colSpan={8} className="text-center py-12 text-xl">
								Es wurden keine Produkte gefunden
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			{Array.isArray(customProductList) && customProductList.length > 0 ? (
				<div className="flex h-12 w-full items-center justify-center gap-4 -space-x-px text-sm py-12">
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectFirst}
					>
						Start
					</button>
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectDec}
					>
						<ChevronLeftIcon />
						Zurück
					</button>
					{renderPageNumbers}
					<button
						className="flex items-center justify-center rounded-xl px-4 py-2 hover:bg-secondary"
						onClick={handlePageSelectInc}
					>
						Weiter
						<ChevronRightIcon />
					</button>
					<button
						className="rounded-lg border-[1px] border-sidebarGray-400 px-2 py-1"
						onClick={handlePageSelectLast}
					>
						Ende
					</button>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default CustomProductTable;
