// src/components/CategoryList.tsx

import React, { useContext, useEffect, useState } from "react";
import { ContentContext } from "../../helper/ContentContext"; // Adjust the import path as needed
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { Table, TableCell, TableHead, TableRow } from "../../../components/ui/table";
import {
	DownloadIcon,
	GlobeIcon,
	ImageIcon,
	Pencil2Icon,
	QuestionMarkCircledIcon,
	TrashIcon,
} from "@radix-ui/react-icons";
import {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from "../../../components/ui/drawer";
import { Card } from "../../../components/ui/card";
import ContentCategory from "../../../arcView/types/ContentCategory";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../components/ui/tooltip";

const ContentControl: React.FC = () => {
	const { categories, fetchCategories, addCategory, deleteCategory, uploadImages, deleteImage } =
		useContext(ContentContext);
	const [newCategoryName, setNewCategoryName] = useState("");
	const [openDrawerId, setOpenDrawerId] = useState<number | null>(null);

	useEffect(() => {
		fetchCategories();
	}, []);

	const handleAddCategory = async () => {
		if (newCategoryName.trim() === "") return;

		await addCategory(newCategoryName);
		setNewCategoryName("");
	};

	const handleDeleteCategory = async (id: number) => {
		await deleteCategory(id);
	};

	return (
		<div className="flex flex-col items-start justify-start p-12">
			<h1 className="font-medium text-2xl mb-8">Kategorien</h1>
			<div className="flex flex-col items-start justify-start w-1/4 gap-4">
				<Input
					type="text"
					value={newCategoryName}
					onChange={(e) => setNewCategoryName(e.target.value)}
					placeholder="Neue Kategorie"
				/>
				<Button onClick={handleAddCategory}>Kategorie hinzufügen</Button>
			</div>
			<ScrollArea className="w-full mt-8 p-4 whitespace-nowrap rounded-md border">
				<Table className="px-8 z-40">
					<TableHead className="w-5/6">
						<span className="text-sm font-bold w-full">Alle Kategorien</span>
					</TableHead>
					<TableHead className="w-1/6">
						<span className="text-sm font-bold">Aktionen</span>
					</TableHead>
					{Array.isArray(categories) &&
						categories.map((category) => (
							<TableRow key={category.id}>
								<TableCell>
									<div className="flex items-center justify-start gap-6">
										<span className="p-2 border border-border rounded-lg font-semibold">
											{category.name}
										</span>
									</div>
								</TableCell>
								<TableCell>
									<div className="flex items-center justify-start gap-2">
										{/* View Gallery Drawer */}
										<Drawer>
											<DrawerTrigger asChild>
												<Button variant="outline" className="flex items-center gap-2">
													<ImageIcon />
													Galerie&nbsp;ansehen
												</Button>
											</DrawerTrigger>
											<DrawerContent className="h-[95vh]">
												<div className="mx-auto w-full max-w-4xl">
													<DrawerHeader>
														<DrawerTitle>Galerie: {category.name}</DrawerTitle>
														<DrawerDescription>
															Sehen Sie sich alle Bilder dieser Kategorie an.
														</DrawerDescription>
													</DrawerHeader>
													<div>
														<Card className="w-full h-full gap-4 p-8 items-start justify-start">
															<div className="grid grid-cols-3 gap-4">
																{Array.isArray(category.images) &&
																	category.images.map((image) => (
																		<div key={image.id} className="relative">
																			<img
																				src={`${image.imageUrl}`}
																				alt={image.title}
																				className="w-full h-auto object-cover rounded-md"
																			/>
																			<button
																				className="absolute top-2 right-2 p-1 bg-red-500 rounded-full hover:cursor-pointer"
																				onClick={() => deleteImage(image.id)}
																			>
																				<TrashIcon className="text-white" />
																			</button>
																		</div>
																	))}
															</div>
														</Card>
													</div>
													<DrawerFooter>
														<DrawerClose asChild>
															<Button variant="outline">Schließen</Button>
														</DrawerClose>
													</DrawerFooter>
												</div>
											</DrawerContent>
										</Drawer>

										{/* Edit Category Drawer */}
										<Drawer
											open={openDrawerId === category.id}
											onOpenChange={(open) => setOpenDrawerId(open ? category.id : null)}
										>
											<DrawerTrigger asChild>
												<Button
													variant="outline"
													className="flex items-center gap-2"
													onClick={() => setOpenDrawerId(category.id)}
												>
													<Pencil2Icon />
													Kategorie&nbsp;bearbeiten
												</Button>
											</DrawerTrigger>
											<DrawerContent className="h-[95vh] z-[90]">
												<ScrollArea className="h-[95vh] w-full">
													<div className="mx-auto w-full max-w-2xl">
														<DrawerHeader>
															<DrawerTitle>Kategorie: {category.name}</DrawerTitle>
															<DrawerDescription>
																Nehmen Sie hier alle gewünschten Änderungen vor.
															</DrawerDescription>
														</DrawerHeader>
														<CategoryEditDrawer
															category={category}
															uploadImages={uploadImages}
															fetchCategories={fetchCategories}
															onClose={() => setOpenDrawerId(null)}
														/>
														<DrawerFooter>
															<DrawerClose asChild>
																<Button variant="outline">Schließen</Button>
															</DrawerClose>
														</DrawerFooter>
													</div>
												</ScrollArea>
											</DrawerContent>
										</Drawer>
										{/* Delete Category Button */}
										<Button
											variant="destructive"
											className="flex items-center gap-2"
											onClick={() => handleDeleteCategory(category.id)}
										>
											<TrashIcon />
											Kategorie&nbsp;löschen
										</Button>
									</div>
								</TableCell>
							</TableRow>
						))}
				</Table>
			</ScrollArea>
		</div>
	);
};

interface CategoryEditDrawerProps {
	category: ContentCategory;
	uploadImages: (categoryId: number, images: File[], title?: string) => Promise<void>;
	fetchCategories: () => void;
	onClose: () => void;
}

const CategoryEditDrawer: React.FC<CategoryEditDrawerProps> = ({
	category,
	uploadImages,
	fetchCategories,
	onClose,
}) => {
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const [isDragging, setIsDragging] = useState<boolean>(false);
	const [title, setTitle] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const handleImageHoldForUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(event.target.files || []);
		setSelectedImages((prevImages) => [...prevImages, ...files]);
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const files = Array.from(event.dataTransfer.files);
		setSelectedImages((prevImages) => [...prevImages, ...files]);
		setIsDragging(false);
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	const handleDragEnter = () => {
		setIsDragging(true);
	};

	const handleDragLeave = () => {
		setIsDragging(false);
	};

	const removeImage = (indexToRemove: number) => {
		setSelectedImages((prevImages) => prevImages.filter((_, index) => index !== indexToRemove));
	};

	const handleAddImageToCategory = async () => {
		if (selectedImages.length === 0) return;
		setIsLoading(true);
		try {
			await uploadImages(category.id, selectedImages, title);
			setSelectedImages([]);
			fetchCategories();
			onClose();
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div>
			<Card className="w-full h-full gap-4 py-8 px-16 items-start justify-start">
				<h3 className="mb-2 text-lg font-medium">Bilder hochladen</h3>
				<div className="py-3">
					<label htmlFor="imageTitle">Name</label>
					<span className="flex items-center justify-center gap-2">
						<Input
							id="imageTitle"
							type="text"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							required
						/>
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger asChild>
									<Button variant="outline">
										<QuestionMarkCircledIcon />
									</Button>
								</TooltipTrigger>
								<TooltipContent>
									<p>
										Der übergebene Titel wird auf jedes Bild gleichermaßen angewendet.
										<br />
										Sollte es sich um einzigartige Bilder handeln mit besonderer Bezeichnung,
										<br />
										sollten diese separat hochgeladen werden.
									</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					</span>
				</div>
				<div
					className={`border-2 border-dashed border-gray-400 p-4 rounded-lg w-full flex justify-center items-center ${
						isDragging ? "bg-gray-200" : "bg-transparent"
					}`}
					onDrop={handleDrop}
					onDragOver={handleDragOver}
					onDragEnter={handleDragEnter}
					onDragLeave={handleDragLeave}
				>
					{isDragging ? (
						<p className="text-gray-800 flex items-center justify-center gap-4">
							<DownloadIcon className="w-16 h-16" />
							Lassen Sie die Dateien los, um sie hochzuladen
						</p>
					) : (
						<label className="cursor-pointer text-gray-500">
							<span className="flex items-center justify-center gap-4">
								<ImageIcon className="w-16 h-16" />
								Ziehen Sie Ihre Bilder hierher oder klicken Sie
							</span>
							<input
								type="file"
								accept="image/*"
								multiple
								onChange={handleImageHoldForUpload}
								className="hidden"
							/>
						</label>
					)}
				</div>
				{selectedImages.length > 0 && (
					<div className="mt-4 grid grid-cols-2 gap-4">
						{selectedImages.map((image, index) => {
							const imageUrl = URL.createObjectURL(image);
							return (
								<div key={index} className="relative">
									<img
										src={imageUrl}
										alt={`preview-${index}`}
										className="w-full h-auto object-cover rounded-md"
									/>
									<button
										className="absolute right-1 top-1 p-1 bg-red-500 rounded-full hover:cursor-pointer"
										onClick={() => removeImage(index)}
									>
										<TrashIcon className="text-white" />
									</button>
								</div>
							);
						})}
					</div>
				)}
			</Card>
			<Button
				onClick={handleAddImageToCategory}
				disabled={selectedImages.length === 0 || isLoading}
				className="mt-4 w-full"
			>
				{isLoading ? "Speichert..." : "Änderungen speichern"}
			</Button>
		</div>
	);
};

export default ContentControl;
